.buttbr Button {
  margin-left: 50px;
}
#br2 {
  display: none;
}

@media screen and (max-width: 1366px) {
  .buttbr Button {
    width: 320px;
    position: relative;
    right: 122px;
  }
}

@media screen and (max-width: 1280px) {
  .buttbr Button {
    position: right;
    right: 134px;
  }
}

@media only screen and (max-width: 700px) {
  .buttbr Button {
    position: relative;
    left: 93px;
    bottom: 33px;
  }
  .buttbr {
    height: 0;
  }
  .addbr {
    margin-left: 290px;
    margin-top: -40px;
  }
  #br {
    margin-left: 50px;
  }
  #br1 {
    display: none;
  }
  #br2 {
    display: flex;
    padding: 0;
    width: 100%;
  }
  .rowbr {
    margin-top: 10px;
    margin-left: 26px;
    width: 100%;
    flex-wrap: nowrap;
  }
  #raiso {
    padding-left: 15px;
  }
  #pj {
    padding-left: 20px;
  }
  #mont {
    margin-right: 7px;
  }
}
