#fc2 {
  display: none;
}

@media only screen and (max-width: 600px) {
  #num {
    padding-left: 25px;
  }
  #typ {
    padding-left: 25px;
  }
  #dat {
    padding-left: 25px;
  }
  #code {
    padding-left: 20px;
  }
  #rais {
    padding-left: 25px;
  }
  #mont {
    padding-left: 10px;
  }
  #vend {
    padding-left: 25px;
  }
  #fc1 {
    display: none;
  }
  #fc2 {
    display: flex;
    padding: 0;
    width: 100%;
  }
  .rowfc {
    margin-top: 10px;
    margin-left: 26px;
    width: 100%;
    flex-wrap: nowrap;
  }
  #fc {
    margin-left: 50px;
  }
  .addfc {
    margin-left: 290px;
    margin-top: -40px;
  }
}
